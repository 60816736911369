(function($) {

	var selector = '#g-product-search';

	var init = function() {

		var me = this;
		var lD = $(selector);
		var lDLength = lD.length;
		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');
		var ww = $(window).width();

		var s = $(selector).selectize({
			valueField: 'uri',
			labelField: 'description',
			searchField: 'description',
			create: false,
			createOnBlur: true,
			openOnFocus: false,
			// delimiter: ';',
			// hideSelected: true,
			persist: false,
			highlight: false,
			score: function() { return function() { return 1; }; }, // allow all to appear from last search until new result is loaded.
			loadOption: 'remote_score',
			onChange: function(value) {
				if (!value.length) return;
				window.location.href = value;
			},
			render: {
				option: function(item, escape) {
					return '<div>' +
						'<img class="img-responsive" style="max-width: 40px;" src="' + escape(item.img) + '" alt="">' +
						'<span class="title">' +
						'<span class="name">' + escape(item.description) + '</span>' +
						'</span>' +
						'<span class="description">' + escape(item.category || '') + '</span>' +
						'</div>';
				}
			},
			load: function(query, callback) {
				if (!query.length) return callback();
				var self = this;
				$.ajax({
					url: '/products/search.json?query=' + encodeURIComponent(query),
					type: 'GET',
					dataType: 'json',
					error: function() {
						if(typeof console === 'object') { console.log('Error',true); }
						callback();
					},
					success: function(res) {
						self.clearOptions();
						if(res.records && typeof res.records === 'object') {
							callback(res.records.slice(0, 20));
						} else {
							callback();
						}
					}
				});
			}
			,onDropdownOpen: function () {
				// Manually prevent dropdown from opening when there is no search term
				if (!this.lastQuery.length) {
					this.close();
				}
			},onType: function (str) {
				// Close drop down when no search is typed.
				if (str === "") {
					this.close();
				}
			}, onItemAdd: function() {
				this.close();
			}, onFocus: function() {
				// $(s).closest('div').scrollToTop(10);
			}
		});
	};

	$(document).ready(function() {
		var lD = $(selector);
		if (lD && lD.get(0)) {
			init();
		}
	});
})(jQuery);



