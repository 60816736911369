(function($) {

	var dzSelector = '.lazySelectize';

	var init = function() {

		var me = this;

		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');

		var options = {};
		$('select.lazySelectize').selectize(options);

	};

	$(document).ready(function() {
		var lD = $(dzSelector);
		if (lD && lD.get(0)) {
			init();
		}
	});
})(jQuery);



