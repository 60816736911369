
/**
 * Generate Dynamic IDs
 * @constructor
 */
function Generator() {};
Generator.prototype.rand =  Math.floor(Math.random() * 26) + Date.now();
Generator.prototype.getId = function() {
	return 'gen-' + this.rand++;
};
var idGen = new Generator();
