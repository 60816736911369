/**
 * ICT SUEDWERK Application
 *
 * @author Chris
 * @copyright (c)yellowspace
 * @date 15.01.2017
 * @version   $Id$
 *
 * @license checkout.js is licensed under the terms of the Open Source
 * LGPL 3.0 license. Commercial use is permitted to the extent that the
 * code/component(s) do NOT become part of another Open Source or Commercially
 * licensed development library or toolkit without explicit permission.
 *
 * License details: http://www.gnu.org/licenses/lgpl.html
 */

(function($) {
	
	var debug = false;
	
	var stepNavItems, 
		stepContentItems,
		steps,
		activateStep,
		paymenttypes;

	startUp = function() {
		stepNavItems = $('ul.setup-panel li a');
		stepContentItems = $('.setup-content');
		paymenttypes = $('.paymenttype input');

		steps = [
			{
				id: 'step-1'
			},
			{
				id: 'step-2'
			},
			{
				id: 'step-3'
			},
			{
				id: 'step-4'
			}
		];

		for(var i=0;i < steps.length; i++) {
			steps[i].c = $('#'+steps[i].id);
			steps[i].n = $('ul.setup-panel li:eq('+i+')');
			steps[i].ch = '#'+steps[i].id;
		}

	};


	init = function() {
		startUp();
		stepContentItems.hide();
		if(debug) stepContentItems.show();
		initEvents();

		if(typeof errorCode != 'undefined' && errorCode) {
			activeStep = 2;
			for(var i=0;i < activeStep; i++) {
				activateStep(i);
			}
			gotoStep(activeStep);
		}
		else {
			activeStep = 0;
			$('ul.setup-panel li:eq('+ activeStep +') a').trigger('click');
		}
	};

	initEvents = function() {
		var _this = this;

		stepNavItems.click(function(e)
		{
			e.preventDefault();
			var s = _getRecordIndex(steps,'ch',$(this).attr('href'));
			var item = steps[s].n;
			if (!item.hasClass('disabled')) {
				gotoStep(s);
			}
		});

		$('input[required],select[required]').blur(function(e) {
			checkField(e,this);
		});

		$('#project-submit-button').on('click',function() {
			setData('project',1);
		});

		$('#billing-submit-button').on('click',function() {
			setData('billing',2);
		});

		$('#shipping-submit-button').on('click',function() {
			setData('shipping',3);
		});

		$('#acceptTerms').on('change',function() {
			checkTerms(this);
		});

		$('#use_billing_address').on('change',function() {
			toggleShippingAdresss(this);
		});

		$('.form').on('submit',function(e) {
			validateAll(event,this);
		});


	};


	activateStep = function(s) {
		$('ul.setup-panel li:eq('+s+')').removeClass('disabled');
	};

	gotoStep = function(s) {

		// validate form!

		activateStep(s);

		// set navi
		stepNavItems.closest('li').removeClass('active');
		steps[s].n.addClass('active');

		// show next step
		steps[activeStep].c.hide();
		steps[s].c.show();
		activeStep = s;
		window.scrollTo(0,0);

		// last step, disable all before
		if(s == (steps.length -1)) {
			for(var i=0; i < (steps.length-1);i++) {
				$('ul.setup-panel li:eq('+i+')').addClass('disabled');
			}
		}
	};

	// -------- Contact DATA ------

	setData = function(t,s) {
		var _this = this;
		var form = $('form.'+t);
		var data = form.serialize();
		modal(true);
		removeErrors(form);
		$.post('/api/v1/store/'+t,data,function(data) {
			modal(false);

			if(typeof console === 'object') { console.log('data',data); }

			if(data.errors && data.errors) {
				setErrors(data,t);
			}
			else {
				gotoStep(s);
			}
		},'json');
	};

	checkTerms = function(terms) {
		if(terms && terms.checked) {
			$('#acceptTermsH').val('y');
		} else {
			$('#acceptTermsH').val('n');
		}
	};

	toggleShippingAdresss = function(ba) {
		if(typeof console === 'object') { console.log('toggleShippingAdresss',ba); }
		if(ba && ba.checked) {
			$('.shipping .panel-body').hide(true);
		} else {
			$('.shipping .panel-body').show(true);
		}
	};

	checkField = function(e,f) {
//		return true;
		if(typeof console === 'object') { console.log('checkField',e,f); }
		if(f && typeof f.checkValidity == 'function') {
			if(f.checkValidity()) {
				removeError(f);
			} else {
				setError(f);
			}
		}
	};

	setError = function(f) {
		$(f).closest('.form-group').removeClass('has-success').addClass('has-error');
	};

	removeError = function(f) {
		if($(f).closest('.form-group').hasClass('noSuccess')) {
			$(f).closest('.form-group').removeClass('has-error');
		} else {
			$(f).closest('.form-group').removeClass('has-error').addClass('has-success');
		}
	};

	removeErrors = function(f) {
		var _this = this;
		var fields = $(f).find('input,select,textarea');
		fields.each(function() {
			removeError(this);
		});

		resetAlert();
	};

	resetAlert = function() {
		$(".alert").hide();
	};

	validateAll = function(event) {
		if(event) {
			if ( event.preventDefault )
				event.preventDefault()
			else event.returnValue = false;
		}
		return false;
	};


	setErrors = function(data,t) {

		var alertMsg = '<h4>'+$L('error')+'</h4><p>'+$L('form_please_check_fields_below')+'</p>';
		var alert = $(".alert"),
			error,
			e,
			i;
		alert.html(alertMsg);

		if(data.success === false
			&& ((!data.errors) || (data.errors.length == 0) || (data.errors.msg)))
		{
			if(!data.errors) data.errors = {};
			alertMsg = '<h4>Error!</h4><p>Sorry, an unknown error occured...</p>';
			if(data.errors.msg) alertMsg = data.errors.msg;
			alert.html(alertMsg);
			alert.show();
		} else {
			if(data.errors) {
				window.scrollTo(0,0);
				alert.show();
				for (i in data.errors) {
					error = data.errors[i];
					e = $('#'+i);
					if(!e.get(0)) {
						e = $('.'+t+' .'+i);
					}
					setError(e);
				}
			}
		}
	};

	modal = function(s) {
		var pleaseWaitDiv = $('#progressModal');

		if(s) {
			pleaseWaitDiv.modal({
				backdrop: 'static',
				keyboard: false
			});

//			pleaseWaitDiv.on('shown.bs.modal', function() {
//				$(this).find('.modal-dialog').css({
//					'margin-top': function () {
//						return -($(this).outerHeight() / 2);
//					},
//					'margin-left': function () {
//						return -($(this).outerWidth() / 2);
//					}
//				});
//			});
		}
		else pleaseWaitDiv.modal('hide');
	};

	
	$(document).ready(function() {
		init();
	});





	/**
	 * get the position in an array
	 *
	 * @param ar
	 * @param n
	 * @param v
	 * @returns {*}
	 * @private
	 */
	_getRecordIndex = function(ar,n,v)
	{
		ar = ar || [];
		var r = false;

		for(var i=0;i<ar.length;i++)
		{
			if(ar[i][n]  == v)
			{
				r = i;
			}
		}
		return r;
	};

	numberFormat = function (number, decimals, dec_point, thousands_sep) {
		var exponent = "", z, i;
		var numberstr = number.toString ();
		var eindex = numberstr.indexOf ("e");
		if (eindex > -1)
		{
			exponent = numberstr.substring (eindex);
			number = parseFloat (numberstr.substring (0, eindex));
		}

		if (decimals != null)
		{
			var temp = Math.pow (10, decimals);
			number = Math.round (number * temp) / temp;
		}
		var sign = number < 0 ? "-" : "";
		var integer = (number > 0 ?
			Math.floor (number) : Math.abs (Math.ceil (number))).toString ();

		var fractional = number.toString ().substring (integer.length + sign.length);
		dec_point = dec_point != null ? dec_point : ".";
		fractional = decimals != null && decimals > 0 || fractional.length > 1 ?
			(dec_point + fractional.substring (1)) : "";
		if (decimals != null && decimals > 0)
		{
			for (i = fractional.length - 1, z = decimals; i < z; ++i)
				fractional += "0";
		}

		thousands_sep = (thousands_sep != dec_point || fractional.length == 0) ?
			thousands_sep : null;
		if (thousands_sep != null && thousands_sep != "")
		{
			for (i = integer.length - 3; i > 0; i -= 3)
				integer = integer.substring (0 , i) + thousands_sep + integer.substring (i);
		}

		return sign + integer + fractional + exponent;
	};

	in_array = function (n, a) {
		var l = a.length;
		for (var i = 0; i < l; i++) {
			if (a[i] == n) {
				return i;
			}
		}
		return false;
	};


})(jQuery);



