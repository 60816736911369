
function initializeGmap() {

	if(!document.getElementById('gmap')) {
		return false;
	}

	var myLatLng = new google.maps.LatLng(48.032116, 11.582621);
	var mapOptions = {
		zoom: 15,
		center: myLatLng,
		scrollwheel: false
	};
	var map = new google.maps.Map(document.getElementById('gmap'),
		mapOptions);

	var contentString = '<div id="googleInfoWindow">'+
		'<div id="siteNotice">'+
		'</div>'+
		'<h4 id="firstHeading">ICT SUEDWERK GmbH</h4>'+
		'<div id="bodyContent">'+
		'<p>' +
		'<strong>TIM Solutions & Services</strong><br>' +
		'Bajuwarenring 12a<br />'+
		'D-82041 Oberhaching</p>'+
		'<p>Tel.: +49 89 21231020<br />'+
		'Fax +49 89 212310210</p>'+	'</div>'+
		'</div>';

	var infowindow = new google.maps.InfoWindow({
		content: contentString,
		maxWidht: 350
	});

	var marker = new google.maps.Marker({
		position: myLatLng,
		//animation: google.maps.Animation.DROP,
		map: map,
		title: 'ICT SUEDWERK GmbH',
		//label: 'CEDES: GmbH'
		labelContent: "ICT SUEDWERK GmbH",
		labelAnchor: new google.maps.Point(3, 30),
		labelClass: "labels", // the CSS class for the label
		labelInBackground: false
	});

	google.maps.event.addListener(marker, 'click', function() {
		infowindow.open(map,marker);
	});
	infowindow.open(map,marker);
}
