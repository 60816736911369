(function($,w) {

	var dzSelector = '.lazyDropzone';

	var initDropzone = function() {

		var me = this;

		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');

		var $uploadForm = $(dzSelector).dropzone({
			init: function(a) {


			},
			dictDefaultMessage: $L('upload_file'),
			method:         'post',
			url:            '/api/v1/store/upload',
			paramName:      'file',
			autoProcessQueue: true,
			maxFiles:        5,
			acceptedFiles:  'image/*,application/pdf,.dxf',
			clickable:      true,
			previewsContainer: '.previews-container',
			thumbnailWidth: 225
		});

		me.dz = Dropzone.forElement(dzSelector);

		// Events: thumbnail, complete

		me.dz.on('sending', function (file, xhr, formData) {
				//if(typeof console === 'object') { console.log('dropzone.sending',file); }
			})
			.on('drop', function (evt) {
				//if(typeof console === 'object') { console.log('dropzone.drop',evt); }
				//onFileSelected(evt);
			})
			//.on('uploadprogress', function (file, progress, bytesSent) {
			//	if(typeof console === 'object') { console.log('dropzone.uploadprogress',file); }
			//})
			.on('success', function (file, responseText, e) {
				//if(typeof console === 'object') { console.log('dropzone.success',file); }
			})
			.on('error', function (file, xhr) {
				if(typeof console === 'object') { console.log('dropzone.error',file); }
			})
			.on('thumbnail', function (file, dataUrl) {
				//if(typeof console === 'object') { console.log('dropzone.thumbnail',file,dataUrl,$(file.previewElement)); }

				var previewElement = $(file.previewElement);
				//if(previewElement) {
				//	previewElement.find('.dz-image').addClass('cover').css({
				//		backgroundImage: 'url('+dataUrl+')'
				//	});
				//	previewElement.find('.dz-image img').css({visibility:'hideen'});
				//}

				// add delete File
				var details = previewElement.find('.dz-details');
				if(details && details.get(0)) {
					details.prepend('<a href="#" class="delete"><i class="icon-trash-empty"></i></a> ');
					var del = details.find('.delete');
					del.on('click',function(e) {
						e.stopPropagation();
						e.preventDefault();
						deleteUploadedFile(file,previewElement);
					});
				}

				if (previewElement && file.type.match(/\.dxf/)) {
					var reader = new FileReader();
					reader.options = {
						file:file,
						previewElement:previewElement
					};
					//reader.onprogress = updateProgress;
					reader.onloadend = onDXFFileUploadSuccess;
					//reader.onabort = abortUpload;
					//reader.onerror = errorHandler;
					reader.readAsText(file);
				}
			})
			.on("addedfile", function (file) {
				//if(typeof console === 'object') { console.log('dropzone.addedfile',file); }
				$('.hide-after-adding-file').hide();
				//if(typeof console === 'object') { console.log('x file.type '+file.type+': %o',file.type.match(/\.dxf/)); }
				if (file.type.match(/\.dxf/)) {
					// This is not an image, so Dropzone doesn't create a thumbnail.
					// Set a default thumbnail:
					me.dz.emit("thumbnail", file, "http://dev.ict-suedwerk.de/local/media/_img/favicons/120x120.png");
				}
			});

		dropzoneUploadedShowFiles(me.dz);
	};

	function deleteUploadedFile(file,previewElement) {
		if(typeof console === 'object') { console.log('DELETE file,previewElement',file,previewElement); }

		$.ajax({
			type: "POST",
			url: '/api/v1/store/deletefile',
			data: {file:file.name},
			success: function() {
				previewElement.remove();
			},
			dataType: 'json'
		});

	};

	function dropzoneUploadedShowFiles(myDropzone) {

		function addFile(mockFile) {

			//var mockFile = { name: "Filename", size: 12345, type: 'image/png' };

			// Call the default addedfile event handler
			myDropzone.emit("addedfile", mockFile);

			// And optionally show the thumbnail of the file:
			myDropzone.emit("thumbnail", mockFile, mockFile.url);

			// Or if the file on your server is not yet in the right
			// size, you can let Dropzone download and resize it
			// callback and crossOrigin are optional.
			//myDropzone.createThumbnailFromUrl(file, "http://dev.ict-suedwerk.de/local/media/_img/favicons/120x120.png", function() {
			//
			//});

			// Make sure that there is no progress bar, etc...
			myDropzone.emit("complete", mockFile);

			// If you use the maxFiles option, make sure you adjust it to the
			// correct amount:
			var existingFileCount = 1; // The number of files already uploaded
			myDropzone.options.maxFiles = myDropzone.options.maxFiles - existingFileCount;

		};

		if(w.uploadedFiles) {
			for(var i=0; i < w.uploadedFiles.length; i++) {
				addFile(w.uploadedFiles[i]);
			}
		}

	};

	function onDXFFileUploadSuccess(evt){
		var fileReader = evt.target;
		if(fileReader.error) return console.log("error onloadend!?");
		var parser = new window.DxfParser();
		var dxf = parser.parseSync(fileReader.result);

		var previewElement = $(fileReader.options.previewElement);
		var id = idGen.getId();
		previewElement.find('.dz-image img').css({display:'none'});
		previewElement.find('.dz-image').append('<div id="'+id+'"></div>');

		// Three.js changed the way fonts are loaded, and now we need to use FontLoader to load a font
		//  and enable TextGeometry. See this example http://threejs.org/examples/?q=text#webgl_geometry_text
		//  and this discussion https://github.com/mrdoob/three.js/issues/7398
		var font;
		var loader = new THREE.FontLoader();
		loader.load( '/app/fonts/helvetiker_regular.typeface.json', function ( response ) {
			font = response;
			cadCanvas = new ThreeDxf.Viewer(dxf, document.getElementById(id), 120, 120, font);
		});
	}

	/* THREE */

	function threejsScene(canvas,settings) {

		var renderer,
			scene,
			camera,
			myCanvas = document.getElementById(canvas);


		//RENDERER
		renderer = new THREE.WebGLRenderer({canvas: myCanvas, antialias: true, alpha: true});
		renderer.setClearColor(0xffffff,0);
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(settings.width, settings.height);

		//CAMERA
		camera = new THREE.PerspectiveCamera(1, settings.width / settings.height, 1, 1000);

		//SCENE
		scene = new THREE.Scene();

		//LIGHTS
		var light = new THREE.AmbientLight(0xffffff, 1);
		scene.add(light);
		//
		//var light2 = new THREE.PointLight(0xffffff, 1);
		//scene.add(light2);

		return {
			renderer:renderer,
			scene:scene,
			camera:camera,
			myCanvas:myCanvas
		};

	};


	function createCircle() {
		var geometry = new THREE.CircleGeometry( 0.8, 64 );
		return geometry;
	};

	function createTriangle(o) {
		var geometry = new THREE.Geometry();
		geometry.vertices.push(
			new THREE.Vector3( -10,  10, 0 ),
			new THREE.Vector3( -10, -10, 0 ),
			new THREE.Vector3(  10, -10, 0 )
		);

		//geometry.vertices.push(
		//	new THREE.Vector3( 0,  0, 0 ),
		//	new THREE.Vector3( 100, 0, 0 ),
		//	new THREE.Vector3(  100, 100, 0 )
		//);


		geometry.faces.push( new THREE.Face3( 0, 1, 2 ) );
		geometry.computeFaceNormals();
		return geometry;
	};

	function createRectangle(o) {

		var geometry = new THREE.BoxGeometry( 2, 2, 0 );

		return geometry;
	};

	function createMaterial(o) {
		var material = new THREE.MeshLambertMaterial({color: 0x76b839});
		var mesh = new THREE.Mesh(o.geometry, material);
		mesh.position.z = -100;

		o.scene.add(mesh);
		return mesh;
	};

	function createLine(o) {
		var material = new THREE.LineBasicMaterial( { color: 0x76b839 } );
		o.scene.add( new THREE.Line(o.geometry, material));
	};

	function renderScene(o) {


		var delta = 0;
		function render() {
			//mesh.rotation.x += 0.01;
			//mesh.rotation.y += 0.01;

			//delta += 0.1;
			//geometry.vertices[0].x = -25 + Math.sin(delta) * 50;
			//geometry.verticesNeedUpdate = true;

			o.renderer.render(o.scene, o.camera);

			requestAnimationFrame(render);
		}

		render();
	};

	$.fn.myExtension = function() {};

	$(document).ready(function() {
		var lD = $(dzSelector);
		if (lD && lD.get(0)) {
			initDropzone();


			var rectangle = threejsScene('rectanglescene',{width: 100,height:100});
			rectangle.geometry = createRectangle();
			rectangle.mesh = createMaterial(rectangle);
			renderScene(rectangle);


			var triangle = threejsScene('trianglescene',{width: 100,height:100});
			triangle.geometry = createTriangle();
			triangle.mesh = createMaterial(triangle);
			renderScene(triangle);
			//
			var circle = threejsScene('circlescene',{width: 100,height:100});
			circle.geometry = createCircle();
			circle.mesh = createMaterial(circle);
			renderScene(circle);

		}
	});


})(jQuery,window);



// Prevent Dropzone from auto discovering this element:
Dropzone.options.fileUpload = false;
// This is useful when you want to create the
// Dropzone programmatically later

// Disable auto discover for all elements:
//Dropzone.autoDiscover = false;




function dxfUpload() { // not in use!

	var progress = document.getElementById('file-progress-bar');
	var $progress = $('.progress');

	var $cadview = $('#cad-view');
	var cadCanvas;

	// Setup the dnd listeners.
	var dropZone = $('.drop-zone');
	dropZone.on('dragover', handleDragOver, false);
	dropZone.on('drop', onFileSelected, false);

	document.getElementById('dxf').addEventListener('change', onFileSelected, false);


	function onFileSelected(evt) {
		progress.style.width = '0%';
		progress.textContent = '0%';

		var file = evt.target.files[0];
		var output = [];
		output.push('<li><strong>', encodeURI(file.name), '</strong> (', file.type || 'n/a', ') - ',
			file.size, ' bytes, last modified: ',
			file.lastModifiedDate ? file.lastModifiedDate.toLocaleDateString() : 'n/a',
			'</li>');
		document.getElementById('file-description').innerHTML = '<ul>' + output.join('') + '</ul>';

		$progress.addClass('loading');

		var reader = new FileReader();
		reader.onprogress = updateProgress;
		reader.onloadend = onSuccess;
		reader.onabort = abortUpload;
		reader.onerror = errorHandler;
		reader.readAsText(file);
	}

	function abortUpload() {
		console.log('Aborted read!')
	}

	function errorHandler(evt) {
		switch(evt.target.error.code) {
			case evt.target.error.NOT_FOUND_ERR:
				alert('File Not Found!');
				break;
			case evt.target.error.NOT_READABLE_ERR:
				alert('File is not readable');
				break;
			case evt.target.error.ABORT_ERR:
				break; // noop
			default:
				alert('An error occurred reading this file.');
		}
	}

	function updateProgress(evt) {
		console.log('progress');
		console.log(Math.round((evt.loaded /evt.total) * 100));
		if(evt.lengthComputable) {
			var percentLoaded = Math.round((evt.loaded /evt.total) * 100);
			if (percentLoaded < 100) {
				progress.style.width = percentLoaded + '%';
				progress.textContent = percentLoaded + '%';
			}
		}
	}

	function onSuccess(evt){
		var fileReader = evt.target;
		if(fileReader.error) return console.log("error onloadend!?");
		progress.style.width = '100%';
		progress.textContent = '100%';
		setTimeout(function() { $progress.removeClass('loading'); }, 2000);
		var parser = new window.DxfParser();
		var dxf = parser.parseSync(fileReader.result);

		// Three.js changed the way fonts are loaded, and now we need to use FontLoader to load a font
		//  and enable TextGeometry. See this example http://threejs.org/examples/?q=text#webgl_geometry_text
		//  and this discussion https://github.com/mrdoob/three.js/issues/7398
		var font;
		var loader = new THREE.FontLoader();
		loader.load( '/app/fonts/helvetiker_regular.typeface.json', function ( response ) {
			font = response;
			cadCanvas = new ThreeDxf.Viewer(dxf, document.getElementById('cad-view'), 400, 400, font);
		});

	}

	function handleDragOver(evt) {
		evt.stopPropagation();
		evt.preventDefault();
		evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
	}

};