(function($) {

	var selector = '.table-product';

	var init = function() {

		var me = this;
		var lD = $(selector);
		var lDLength = lD.length;
		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');
		var ww = $(window).width();

		var options = {
			lengthChange: false,
			// pageLength: 15,
			// lengthMenu: [ 10, 25, 50, 75, 100 ],
			responsive: false,
			order: false,
			ordering:  false,
			fixedColumns: {
				leftColumns: 2
			},
			scrollX:        true,
			scrollCollapse: false, //Allow the table to reduce in height when a limited number of rows are shown.
			// scrollY:        "300px",
			// paging:         false
			"initComplete": function () {
				var api = this.api();
				// if(typeof console === 'object') { console.log('api',this,$(this),api); }
			},
			language: {
				buttons: {
					colvis: $L('columns'),
					print: $L('print'),
					"lengthMenu": "Display _MENU_ records per page",
					"zeroRecords": "Nothing found - sorry",
					"info": "Showing page _PAGE_ of _PAGES_",
					"infoEmpty": "No records available",
					"infoFiltered": "(filtered from _MAX_ total records)"
				}
			}
		};


		lD.each(function(index) {

			// todo: set id in html code to use the state
			var id = idGen.getId();
			$(this).attr('id',id);

			var s = $('#'+id);

			if(ww < 420) {
				options.fixedColumns.leftColumns = 1;
			} else if(ww > 768 && lDLength === 1 && s.hasClass('table-product_single-product')) {
				options.pageLength = 25;
				options.autoWidth = false;
			}

			var table = s.DataTable(options);

			var buttons = new $.fn.dataTable.Buttons(table, {
				buttons: [
					'colvis',
					'print'
				]
			}).container().appendTo($('#'+id+'_wrapper .col-sm-6:eq(0)'));
		});
	};

	$(document).ready(function() {
		var lD = $(selector);
		if (lD && lD.get(0)) {
			init();
		}
	});
})(jQuery);



