(function($) {

	var dzSelector = '.lazyDropzone';

	var initDropzone = function() {

		var me = this;

		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');

		var $uploadForm = $(dzSelector).dropzone({
			init: function(a) {


			},
			dictDefaultMessage: $L('upload_file'),
			method:         'post',
			url:            '/api/v1/picture/upload',
			paramName:      'file',
			autoProcessQueue: true,
			maxFiles:        1,
			acceptedFiles:  'image/*',
			clickable:      true,
			thumbnailWidth: 225
		});

		me.dz = Dropzone.forElement(dzSelector);

		// Events: thumbnail, complete

		me.dz.on('sending', function (file, xhr, formData) {
				if(typeof console === 'object') { console.log('dropzone.sending',file); }
			})
			.on('uploadprogress', function (file, progress, bytesSent) {
				if(typeof console === 'object') { console.log('dropzone.uploadprogress',file); }
			})
			.on('success', function (file, responseText, e) {
				if(typeof console === 'object') { console.log('dropzone.success',file); }
			})
			.on('error', function (file, xhr) {
				if(typeof console === 'object') { console.log('dropzone.error',file); }
			})
			.on("addedfile", function (file) {
				if(typeof console === 'object') { console.log('dropzone.addedfile',file); }
			});
	};

	$(document).ready(function() {
		var lD = $(dzSelector);
		if (lD && lD.get(0)) {
			//initDropzone();
		}
	});


})(jQuery);



